import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Veterans Day`}</em></p>
    <p>{`Overhead Squats 4×4\\@80%1rm`}</p>
    <p>{`Snatch Grip RDL’s 4×4`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Randy”`}</strong></p>
    <p>{`75-Snatches (75/55`}{`#`}{`)`}</p>
    <p>{`for time.`}</p>
    <p>{`*`}{`compare to 5/18/20`}</p>
    <img {...{
      "src": "https://crossfittheville.wordpress.com/6b4a99c6-0f05-4d72-96f5-bb0e5297ced0",
      "alt": null
    }}></img>
    <p><strong parentName="p">{`Background`}</strong>{`: In honor of Randy Simmons, 51, a 27 year LAPD veteran
and SWAT team member who was killed in the line of duty February 6,
2008.`}</p>
    <p>{`The “Randy” Hero WOD was first posted on the CrossFit Main Site as the
workout of the day for Wednesday, February 13, 2008
(`}<a parentName="p" {...{
        "href": "https://wodwell.com/wod/080213/"
      }}>{`080213`}</a>{`).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      